<template>
  <div class="my-1">
    <b-table
      :items="historyData"
      :fields="tableColumns"
      hover
      responsive
      head-variant="info"
      sticky-header
      style="max-height: 65vh"
      thead-class="text-dark align-middle text-nowrap text-center"
      tbody-class="text-dark align-middle text-nowrap text-center"
      class="border-secondary rounded-8px"
      show-empty
      no-border-collapse
      :empty-text="$t('noMatchingResult')"
    >
      <!-- ANCHOR Table Header -->
      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column.key})`]="{ label }"
      >
        <span
          :key="column.label"
          class="text-dark text-nowrap"
        >
          {{ $t(`reservation.historyActions.columns.${label}`) }}
        </span>
      </template>

      <!-- ANCHOR Style for column(s)) -->
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :style="field.style"
        >
      </template>

      <!-- ANCHOR Column orderNo -->
      <template #cell(orderNo)="{ index }">
        {{ historyData.length - index }}
      </template>

      <!-- ANCHOR Column date -->
      <template #cell(date)="{ item }">
        {{ convertISODateTime(item.timestamp).dateTimeSecond }}
      </template>

      <!-- ANCHOR Column action -->
      <template #cell(action)="{ item }">
        <b-badge
          variant="info"
          class="badge-glow"
        >
          {{ getHistoryActionBookingFunction(item.action) || $t(`reservation.historyActions.${item.action}`) }}
        </b-badge>
      </template>

      <template #cell(amount)="{ item }">
        <div class="text-center font-weight-bold">
          <div v-if="item.action === 'SET_LOW_FARE_TRACKING' && !isEmpty(item.newValue)">
            <div
              v-for="(info,indexInfo) of JSON.parse(item.newValue)"
              :key="indexInfo"
            >
              <div>Giá thấp nhất: <span class="text-danger">{{ formatCurrency(info.targetFareAmountBottom) }}</span></div>
              <div>Giá cao nhất: <span class="text-danger">{{ formatCurrency(info.targetFareAmount) }}</span></div>
              <div>Hẹn giờ tắt: <span class="text-danger">{{ convertISODateTime(info.deadline).dateTime.replace(" | ", " ") }}</span></div>
            </div>
          </div>
          <div v-else-if="item.action === 'SET_AUTO_ISSUE_TIME' && !isEmpty(item.newValue)">
            <div>Hẹn giờ xuất vé: <span class="text-danger">{{ convertISODateTime(item.newValue).dateTime.replace(" | ", " ") || '' }}</span></div>
          </div>
          <div v-else-if="item.action === 'ENABLE_EDIT_BOOKING_OUTSIDE' && !isEmpty(item.newValue)">
            {{ $t(item.newValue) }}
          </div>
          <div v-else-if="item.action === 'ASSIGN_BOOKING' && !isEmpty(item.newValue)">
            {{ JSON.parse(item.oldValue).agencyCode }} → {{ JSON.parse(item.newValue).agencyCode }}
          </div>
          <div
            v-else-if="['CALCULATE_PRICE_BARGAIN_FINDER', 'MODIFY_ADD_DOB', 'MODIFY_FFTV'].includes(item.action) && source.includes('VN1A') && !isEmpty(item.newValue)"
            class="d-flex-center"
          >
            <b-button
              v-if="item.newValue"
              v-b-tooltip.hover.window
              v-b-modal.modal-reservation-show-text
              title="Click để xem thông tin"
              variant="flat-dark"
              class="rounded-8px p-50 px-1"
              @click="handleClickDetail(item)"
            >
              <div class="d-flex-center gap-1">
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
                Xem thông tin
              </div>
            </b-button>
          </div>
          <div
            v-else-if="Number(item.newValue) &&!isNaN(Number(item.newValue))"
            class="text-danger"
          >
            {{ formatCurrency(Number(item.newValue)) }}
          </div>
          <div
            v-else
            class="text-danger"
          >
            {{ item.newValue ? (JSON.parse(item.newValue) ? formatCurrency(JSON.parse(item.newValue).amount) : item.newValue) : '' }}
          </div>
        </div>
      </template>

      <!-- ANCHOR Column executor -->
      <template #cell(executor)="{ item }">
        {{ getLocaleFullName(item.modifiedBy) }}
      </template>
    </b-table>

    <ModalShowText
      v-if="showModal"
      :text="fullText"
      :title-modal="`Xem thông tin lịch sử ${action ? $t(`reservation.historyActions.${action}`) : ''}`"
    />
  </div>
</template>

<script>
import {
  BTable, BButton, BBadge, VBTooltip,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ref } from '@vue/composition-api'
import isNaN from 'lodash/isNaN'

import {
  INVOICE_ACTIONS_HISTORY_TABLE_COLUMNS as tableColumns,
} from '@/constants/invoice'
import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'
import store from '@/store'
import ModalShowText from '@/views/apps/reservation/find-ticket/ModalShowText.vue'

import {
  formatCurrency, convertISODateTime,
} from '@core/utils/filter'

export default {
  components: {
    BTable,
    BBadge,
    BButton,
    ModalShowText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    historyData: {
      type: Array,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
  },

  setup() {
    const getHistoryActionBookingFunction = action => store.getters['globalConfig/getHistoryActionBookingByCode'](action)
    const fullText = ref('')
    const action = ref('')

    function handleClickDetail(item) {
      try {
        console.log({ item, fullText: JSON.parse(item.newValue) })
        fullText.value = JSON.parse(item.newValue)
      } catch (error) {
        console.log({ error })
        fullText.value = item.newValue
      }
      action.value = item.action
    }
    return {
      getHistoryActionBookingFunction,
      tableColumns,
      getLocaleFullName,
      formatCurrency,
      convertISODateTime,
      fullText,
      isEmpty,
      action,
      isNaN,
      handleClickDetail,
    }
  },
}
</script>
