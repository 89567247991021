var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-1"
  }, [_c('b-table', {
    staticClass: "border-secondary rounded-8px",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "items": _vm.historyData,
      "fields": _vm.tableColumns,
      "hover": "",
      "responsive": "",
      "head-variant": "info",
      "sticky-header": "",
      "thead-class": "text-dark align-middle text-nowrap text-center",
      "tbody-class": "text-dark align-middle text-nowrap text-center",
      "show-empty": "",
      "no-border-collapse": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.historyActions.columns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "table-colgroup",
      fn: function fn(_ref2) {
        var fields = _ref2.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: field.style
          });
        });
      }
    }, {
      key: "cell(orderNo)",
      fn: function fn(_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(_vm.historyData.length - index) + " ")];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.timestamp).dateTimeSecond) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.getHistoryActionBookingFunction(item.action) || _vm.$t("reservation.historyActions.".concat(item.action))) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-center font-weight-bold"
        }, [item.action === 'SET_LOW_FARE_TRACKING' && !_vm.isEmpty(item.newValue) ? _c('div', _vm._l(JSON.parse(item.newValue), function (info, indexInfo) {
          return _c('div', {
            key: indexInfo
          }, [_c('div', [_vm._v("Giá thấp nhất: "), _c('span', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.formatCurrency(info.targetFareAmountBottom)))])]), _c('div', [_vm._v("Giá cao nhất: "), _c('span', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.formatCurrency(info.targetFareAmount)))])]), _c('div', [_vm._v("Hẹn giờ tắt: "), _c('span', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.convertISODateTime(info.deadline).dateTime.replace(" | ", " ")))])])]);
        }), 0) : item.action === 'SET_AUTO_ISSUE_TIME' && !_vm.isEmpty(item.newValue) ? _c('div', [_c('div', [_vm._v("Hẹn giờ xuất vé: "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.convertISODateTime(item.newValue).dateTime.replace(" | ", " ") || ''))])])]) : item.action === 'ENABLE_EDIT_BOOKING_OUTSIDE' && !_vm.isEmpty(item.newValue) ? _c('div', [_vm._v(" " + _vm._s(_vm.$t(item.newValue)) + " ")]) : item.action === 'ASSIGN_BOOKING' && !_vm.isEmpty(item.newValue) ? _c('div', [_vm._v(" " + _vm._s(JSON.parse(item.oldValue).agencyCode) + " → " + _vm._s(JSON.parse(item.newValue).agencyCode) + " ")]) : ['CALCULATE_PRICE_BARGAIN_FINDER', 'MODIFY_ADD_DOB', 'MODIFY_FFTV'].includes(item.action) && _vm.source.includes('VN1A') && !_vm.isEmpty(item.newValue) ? _c('div', {
          staticClass: "d-flex-center"
        }, [item.newValue ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }, {
            name: "b-modal",
            rawName: "v-b-modal.modal-reservation-show-text",
            modifiers: {
              "modal-reservation-show-text": true
            }
          }],
          staticClass: "rounded-8px p-50 px-1",
          attrs: {
            "title": "Click để xem thông tin",
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.handleClickDetail(item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "MoreHorizontalIcon",
            "size": "16"
          }
        }), _vm._v(" Xem thông tin ")], 1)]) : _vm._e()], 1) : Number(item.newValue) && !isNaN(Number(item.newValue)) ? _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(item.newValue))) + " ")]) : _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(item.newValue ? JSON.parse(item.newValue) ? _vm.formatCurrency(JSON.parse(item.newValue).amount) : item.newValue : '') + " ")])])];
      }
    }, {
      key: "cell(executor)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.getLocaleFullName(item.modifiedBy)) + " ")];
      }
    }], null, true)
  }), _vm.showModal ? _c('ModalShowText', {
    attrs: {
      "text": _vm.fullText,
      "title-modal": "Xem th\xF4ng tin l\u1ECBch s\u1EED ".concat(_vm.action ? _vm.$t("reservation.historyActions.".concat(_vm.action)) : '')
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }